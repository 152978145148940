import { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import Footer from './components/Footer';
import FooterMobile from './components/FooterMobile';
import InfoBar from './components/InfoBar';
import closeButton from './images/menu-close-icon.png';
import gwMainLogo from './images/gw-main-logo.png';
import useDeviceSize from './util/DeviceSize';
import Hero from './components/Hero';

function App() {
	const [menuVisible, setMenuVisible] = useState(false);
	const [isMobile, isDesktop] = useDeviceSize();
  const location = useLocation();

	const hideMenu = () => setMenuVisible(false);

	return (
		<>
			<div className="relative">
				<div className={`hidden ${menuVisible ? 'md:flex' : ''} bg-bg h-screen w-40 absolute right-0 top-0 z-50`}>
					<img src={closeButton} onClick={hideMenu} className="absolute w-5 h-5 right-2 top-2" alt=""/>
					<div className="flex flex-col items-center mx-auto space-y-24 list-none py-9">
						<div className="mx-auto">
							<Link to="/" onClick={hideMenu} className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
								HOME
							</Link>
						</div>
						<div>
							<Link to="/coming-soon"
								onClick={hideMenu}
								className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
								GAME
							</Link>
						</div>
						<div>
							<Link to="/coming-soon"
								onClick={hideMenu}
								className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
								MARKET
							</Link>
						</div>
						<div>
							<Link to="/lore"
								onClick={hideMenu}
								className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
								LORE
							</Link>
						</div>
						<div>
							<Link to="/blog"
								onClick={hideMenu}
								className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
								BLOG
							</Link>
						</div>
						<div>
							<Link to="/coming-soon"
								onClick={hideMenu}
								className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
								FAQ
							</Link>
						</div>
					</div>
				</div>
				<div className={`overflow-x-hidden text-white ${location.pathname === '/blog' ? 'bg-blogGray' : 'bg-bg'}`}>
					<InfoBar setMenuVisible={setMenuVisible} />
					{ isMobile &&
						<div className="absolute">
							<Link className="relative z-40 inline-block mx-10 my-2 font-bold text-white whitespace-no-wrap font-display text-lg1" to="/">
								<img src={gwMainLogo} alt="" style={{ maxWidth: 104 }} />
							</Link>
						</div>
					}
					{/*TODO: Swap this section in dynamically for the header when scrolling down the page*/}
					{/* <div className="flex justify-center border-b border-gray-500 border-opacity-50">
						<div className="flex w-8/12 py-7">
							<Link to="/">
								<img src={gwMainLogo} alt="Galaxy Warriors" className="ml-9 mr-98" />
							</Link>
							<div className="flex items-center justify-end flex-grow py-5 space-x-4">
								<Link to="/" className="text-xs font-michroma">HOME</Link>
								<Link to="/coming-soon" className="text-xs font-michroma">GAME</Link>
								<Link to="/coming-soon" className="text-xs font-michroma">FAQ</Link>
								<Link to="/coming-soon" className="text-xs font-michroma">BLOG</Link>
								<Link to="/coming-soon" className="text-xs font-michroma">CONTACT US</Link>
								<Link to="/coming-soon">
									<div
										className="flex items-center h-12 px-5 py-1 bg-gradient-to-tr from-connectButtonLight to-connectButtonDark rounded-connectButton text-connectButton"
										style={{ marginRight: "20px" }}
									>
										<div
											className="font-iceland drop-shadow-connectButton text-connectButton"
											style={{ WebkitTextStroke: ".4px black" }}
										>
											CONNECT WALLET
										</div>
									</div>
								</Link>
							</div>
						</div>
					</div> */}
					<div className="relative">
						{/* start header */}
						<header className="absolute top-0 z-40 block w-full py-2 border-b border-gray-500 border-opacity-50 md:py-0">
							<div className="container px-6">
								{/* start desktop header */}
								<nav className="left-0 flex items-center justify-between md:hidden navbar-expand-lg top">
									<div className="flex flex-wrap items-center justify-between w-full mx-auto">
										<div className="static relative flex justify-start justify-between block w-auto">
											<Link className="relative z-40 inline-block font-bold text-white whitespace-no-wrap font-display text-lg1" to="/">
												<img src={gwMainLogo} alt="" style={{ maxWidth: 225 }} />
											</Link>
										</div>
										<div className="flex items-center flex-grow nav_menu">
											<ul className="flex flex-row items-center ml-auto space-x-6 list-none">
												<li>
													<Link to="/" className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
														HOME
													</Link>
												</li>
												<li>
													<Link to="/coming-soon"
														className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
														GAME
													</Link>
												</li>
												<li>
													<Link to="/coming-soon"
														className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
														MARKET
													</Link>
												</li>
												<li>
													<Link to="/lore"
														className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
														LORE
													</Link>
												</li>
												<li>
													<Link to="/blog"
														className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
														BLOG
													</Link>
												</li>
												<li>
													<Link to="/coming-soon"
														className="inline-block text-xs font-normal text-white uppercase transition-all duration-300 font-michroma hover:text-prm time-shadow">
														FAQ
													</Link>
												</li>
												<li>
													<Link to="/coming-soon"
														className="inline-block w-auto py-3 text-xl font-normal leading-none text-center text-white uppercase duration-300 rounded-full btn px-7 font-iceland time-shadow">
														CONNECT WALLET
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</nav>
								{/* end desktop header */}
							</div>
						</header>
						{/* end header  */}
						{/* hero section */}
						<Hero />
						{/* end hero section */}
						<Outlet />
					</div>

					{/* footer */}
					{isDesktop &&
						<Footer />
					}
					{isMobile && 
						<FooterMobile />
					}
					{/* end footer */}
				</div>
			</div>
		</>
	);
}

export default App;
