import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './components/Home';
import Lore from './components/Lore';
import Blog from './components/Blog';
import RemoveTrailingSlash from './util/RemoveTrailingSlash';
import BlogEntry from './components/BlogEntry';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <RemoveTrailingSlash/>
      <Routes>
        <Route path="/" element={<App />} >
          <Route path="" element={<Home />} />
          <Route path="coming-soon" element={<></>} />
          <Route path="lore" element={<Lore />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:id" element={<BlogEntry />} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
