import { Link } from 'react-router-dom';
import discordIcon from '../images/discord-icon-mobile.png';
import footerLogo from '../images/footer-logo.png';
import pancakeSwap from '../images/pancakeswap-cake-logo.png';
import seamlessSwap from '../images/seamlessswap.png';
import telegramIcon from '../images/telegram-icon-mobile.png';
import twitterIcon from '../images/twitter-icon-mobile.png';

function FooterMobile() {
  return (
    <div className="pt-16 pb-6 bg-black min-w-footer md:min-w-mobile">
      <div className="container px-3">
        <div className="mb-10 lg:mb-0">
          <Link to="/" className="flex justify-center mb-4">
            <img src={footerLogo} alt=""/>
          </Link>
        </div>
        <div className="px-1 my-9 font-michroma">
          <div className="flex-col">
            <div className="mb-5 text-base">
              QUICK LINKS
            </div>
            <div className="mb-2">
              <Link to="/" className="text-xs font-normal leading-normal text-white time-shadow">
                HOME
              </Link>
            </div>
            <div className="mb-2">
              <Link to="/coming-soon" className="text-xs font-normal leading-normal text-white time-shadow">
                ABOUT
              </Link>
            </div>
            <div className="mb-2">
              <Link to="/files/GWT-Litepaper.pdf" target="_blank" className="text-xs font-normal leading-normal text-white time-shadow">
                LITE PAPER
              </Link>
            </div>
            <div className="mb-2">
              <a href="https://forms.gle/L1LwkTJkUHvfihmXA" target="_blank" rel="noreferrer" className="text-xs font-normal leading-normal text-white time-shadow">
                CONTACT US
              </a>
            </div>
            <div className="mb-2">
              <Link to="/coming-soon" className="text-xs font-normal leading-normal text-white time-shadow">
                FAQ
              </Link>
            </div>
            <div>
              <Link to="/blog" className="text-xs font-normal leading-normal text-white time-shadow">
                BLOG
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="mb-4 text-xl font-normal leading-normal text-white uppercase time-shadow font-michroma">SOCIAL</div>
          <div className="flex flex-row justify-center gap-12 mb-8">
            <div>
              <a href="https://twitter.com/_galaxywarriors">
                <img src={twitterIcon} alt="Twitter"/>
              </a>
            </div>
            <div>
              <a href="https://discord.gg/2H6PC25Xc3">
                <img src={discordIcon} alt="Discord"/>
              </a>
            </div>
            <div>
              <a href="https://t.me/GalaxyWarriorsGame">
                <img src={telegramIcon} alt="Telegram"/>
              </a>
            </div>
          </div>
          <div>
            <div className="mb-4 text-xl font-normal leading-normal text-white uppercase time-shadow font-michroma">
							SHEESH
            </div>
            <button onClick={() => window.open('https://dex.seamlessswap.finance/#/swap?outputCurrency=0x564F184692952ADF6A9512a13dc49cAF45E76b12', '_blank')}
              className="relative flex items-center justify-start w-full px-6 pl-4 pr-3 mb-3 font-normal text-left text-white border-2 border-solid rounded-full bg-black-500 border-seamlessSwapYellow font-michroma text-buyTokenButton py-buyTokenButton time-shadow focus:outline-none">
							<img className="inline-block mr-3 bg-black rounded-full" src={seamlessSwap} alt=""/>
							<div className="flex mx-auto">
								Buy on SeamlessSwap
							</div>
            </button>
            <button onClick={() => window.open('https://pancakeswap.finance/swap?outputCurrency=0x564F184692952ADF6A9512a13dc49cAF45E76b12', '_blank')}
              className="relative flex items-center justify-start w-full px-6 pl-4 pr-3 mb-3 font-normal text-left text-white break-words border border-solid rounded-full bg-pancakeSwapBlue font-michroma text-buyTokenButton py-buyTokenButton time-shadow focus:outline-none">
              <img className="inline-block mr-3" src={pancakeSwap} alt=""/>
              <div className="flex mx-auto">
                Buy on Pancakeswap
              </div>
            </button>
            <div className="mt-6 mb-4 text-xl font-normal leading-normal text-white break-words time-shadow font-michroma">
              GalaxyWarriorsToken
            </div>
            <button onClick={() => window.open('https://dex.seamlessswap.finance/#/swap?outputCurrency=0xFA5E7F013588b1c76E7218f2799e125bfD3D8D84', '_blank')}
              className="relative flex items-center justify-start w-full px-6 pl-4 pr-3 mb-3 font-normal text-left text-white break-words border-2 border-solid rounded-full bg-black-500 border-seamlessSwapYellow font-michroma text-buyTokenButton py-buyTokenButton time-shadow focus:outline-none">
              <img className="inline-block mr-3 bg-black rounded-full" src={seamlessSwap} alt=""/>
              <div className="flex mx-auto">
                Buy on SeamlessSwap
              </div>
            </button>
            <button onClick={() => window.open('https://pancakeswap.finance/swap?outputCurrency=0xFA5E7F013588b1c76E7218f2799e125bfD3D8D84', '_blank')}
              className="relative flex items-center w-full px-6 pl-4 pr-3 mb-3 font-normal text-left text-white break-words border border-solid rounded-full bg-pancakeSwapBlue font-michroma text-buyTokenButton py-buyTokenButton time-shadow focus:outline-none">
              <img className="inline-block mr-3" src={pancakeSwap} alt=""/>
              <div className="flex mx-auto">
                Buy on Pancakeswap
              </div>
            </button>
          </div>
        </div>
      </div>
			<div className="text-xs font-normal leading-normal text-center text-white time-shadow font-michroma">
				All rights reserved. <br/>
				{(new Date().getFullYear())} &copy; GALAXYWARRIORS
			</div>
    </div>
  )
}

export default FooterMobile;