import Particles, { Container, Main } from "react-tsparticles";
import { Link, useLocation } from "react-router-dom";

import ParticlesConfig from '../config/particles-config';
import backgroundImage from '../images/hero-background.png';
import playGameChevrons from '../images/play-game-chevrons.png';
import useDeviceSize from "../util/DeviceSize";

const particlesInit = (main: Main) => {
  console.log(main);
};

const particlesLoaded = (container: Container) => {
  console.log(container);
};

function Hero() {
  const [isMobile, isDesktop] = useDeviceSize();
  const location = useLocation();

  const mainButtonContent = location.pathname === '/coming-soon' ?
  <Link to="/"
    className="z-20 flex items-center justify-center inline-block w-auto px-8 py-4 text-lg font-normal leading-normal text-center text-white uppercase rounded-full btn font-michroma time-shadow">
    COMING SOON
  </Link> :
  <Link to="/coming-soon"
    className="z-20 flex items-center justify-center inline-block w-auto px-8 pt-1 pb-2 text-lg font-normal leading-normal text-center text-white uppercase rounded-full btn font-michroma time-shadow">
    PLAY GAME
    <img className="z-20 inline-block w-12 h-12 pt-2 ml-2" src={playGameChevrons} alt="Play Game" />
  </Link>;

  if (location.pathname === '/lore' || location.pathname.startsWith('/blog')) {
    return <></>;
  }

  return (
    <>
      {isDesktop &&
        <>
          <Particles options={ParticlesConfig} init={particlesInit} loaded={particlesLoaded} className='absolute z-10 w-full'/>
          <div className="relative items-center object-cover h-screen bg-center bg-no-repeat bg-cover -z-10"
            style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="container relative z-20 flex flex-wrap items-center justify-center w-full h-screen px-6 mx-auto">
              <button className="flex items-center justify-center pt-20">
                {mainButtonContent}
              </button>
            </div>
          </div>
        </>
      }
      {isMobile &&
        <>
          <img src={backgroundImage} className="w-screen" alt="" />
          <div className="container relative flex flex-wrap items-center justify-center w-full px-6 mx-auto">
            <button className="flex items-center justify-center py-12">
              {mainButtonContent}
            </button>
          </div>
        </>
      }
    </>
  );
}

export default Hero;