import discordIcon from '../images/discord-icon.png';
import gwtIconSmall from '../images/gwt-icon-small.png';
import hamburgerIcon from '../images/hamburger-icon.png';
import sheeshIconSmall from '../images/sheesh-icon-small.png';
import telegramIcon from '../images/telegram-icon.png';
import twitterIcon from '../images/twitter-icon.png';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Subject, Subscription } from 'rxjs';

type InfoBarProps = {
  setMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

type PriceValue = {
	price: string;
	consecutiveFailures?: number;
	totalFailures?: number;
};

type PriceResponse = {
	gwt: PriceValue;
	sheesh: PriceValue;
};

const gwtAddress = '0xFA5E7F013588b1c76E7218f2799e125bfD3D8D84';
const sheeshAddress = '0x564F184692952ADF6A9512a13dc49cAF45E76b12';
const pancakeSwapUrl = (address: string) => `https://pancakeswap.finance/swap?use=v2&inputCurrency=BNB&outputCurrency=${address}&chain=bsc`;

let pricesSubject = new Subject<PriceResponse>();
let pricesSubscription: Subscription;
let updatePricesTimeout: NodeJS.Timeout;
let isInitialLoad: boolean = true;

let getUpdatedPrices = async (): Promise<void> => {
	await axios.get<PriceResponse>(`${process.env.REACT_APP_SERVER_ADDRESS}/getprices/`)
		.then(res => {
			pricesSubject.next(res.data);
			return res;
		})
		.catch(err => {
			console.error(err);
			pricesSubject.next({
				gwt: {
					price: '-',
				},
				sheesh: {
					price: '-',
				},
			});
			return Promise.resolve();
		});
};

function getOutdatedText(priceValue: PriceValue): string {
	let text = `$${priceValue.price}`;
	if (priceValue.consecutiveFailures) {
		text += ` (${Math.floor(priceValue.consecutiveFailures / 2)}m ${30 * (priceValue.consecutiveFailures % 2)}s ago)`;
	} else {
		text += ' (Up to date)';
	}
	return text;
}

function generatePriceSpan(priceValue: PriceValue): React.ReactNode {
	return (
		<span
			className={`ml-1 ${priceValue.consecutiveFailures ? 'text-red-700' : ''}`}
			title={getOutdatedText(priceValue)}
		>
			${priceValue.price}
		</span>
	);
}

function InfoBar(props: InfoBarProps) {
	const [prices, setPrices] = useState<PriceResponse>({gwt: {price: '-'}, sheesh: {price: '-'}});

	useEffect(() => {
		updatePricesTimeout = setInterval(getUpdatedPrices, 30000);
		pricesSubscription = pricesSubject.asObservable().subscribe(setPrices);
		if (isInitialLoad) {
			isInitialLoad = false;
			getUpdatedPrices();
		}
		return () => {
			clearInterval(updatePricesTimeout);
			pricesSubscription.unsubscribe();
		};
	}, [prices]);

  return (
    <div className="flex max-w-full py-2 bg-offBlackBg">
			<div className="flex justify-start flex-grow">
				<div className="flex items-center justify-center ml-10 border-r pr-9 md:pr-2 md:ml-2 border-lineBlue">
					<a href={pancakeSwapUrl(gwtAddress)}>
						<img title="Galaxy Warriors Token (GWT)" src={gwtIconSmall} className="md:w-6 md:h-6" alt="gwt icon" />
					</a>
					{generatePriceSpan(prices.gwt)}
				</div>
				<div className="flex items-center justify-center ml-9 md:ml-2">
					<a href={pancakeSwapUrl(sheeshAddress)}>
						<img title="Sheesh Token" src={sheeshIconSmall} className="md:w-6 md:h-6" alt="sheesh icon" />
					</a>
					{generatePriceSpan(prices.sheesh)}
				</div>
			</div>
			<Link to="/"
				className="flex-col hidden w-auto h-5 px-2 py-1 my-auto text-xs font-normal leading-none text-center text-white uppercase duration-300 rounded-full md:flex justify-items-center btn font-iceland time-shadow"
				style={{ WebkitTextStroke: ".2px black" }}>
				<div className="h-3 my-auto">
				CONNECT WALLET
				</div>
			</Link>
			<img src={hamburgerIcon} alt="" className="hidden w-8 h-6 my-auto ml-2 mr-1 md:block" onClick={() => props.setMenuVisible(true)}/>
			<div className="flex justify-end flex-grow md:hidden">
				<div className="flex items-center ml-9">
					<a
						href="https://t.me/GalaxyWarriorsGame"
						target="_blank"
						rel="noreferrer"
					>
						<img src={telegramIcon} alt="sheesh icon" />
					</a>
				</div>
				<div className="flex items-center ml-9">
					<a
						href="https://discord.gg/2H6PC25Xc3"
						target="_blank"
						rel="noreferrer"
					>
						<img src={discordIcon} alt="sheesh icon" />
					</a>
				</div>
				<div className="flex items-center mr-10 ml-9">
					<a
						href="https://twitter.com/_galaxywarriors"
						target="_blank"
						rel="noreferrer"
					>
						<img src={twitterIcon} alt="gwt icon" />
					</a>
				</div>
			</div>
		</div>
  )
}

export default InfoBar;