import React, { useEffect, useState } from "react";

import characterInfo from '../content/characterInfo.json';
import anomalyIcon from "../images/anomaly_icon.png";
import hydroIcon from "../images/hydro_icon.png";
import infernoIcon from "../images/inferno_icon.png";
import plasmaIcon from "../images/plasma_icon.png";
import terraIcon from "../images/terra_icon.png";
import aesliariHead from "../images/characters/heads/aesliari.png";
import brelgakHead from "../images/characters/heads/brelgak.png";
import drothHead from "../images/characters/heads/droth.png";
import iqrothHead from "../images/characters/heads/iqroth.png";
import peixHead from "../images/characters/heads/peix.png";
import quizonHead from "../images/characters/heads/quizon.png";
import raisutHead from "../images/characters/heads/raisut.png";
import uhlaehHead from "../images/characters/heads/uhlaeh.png";
import zuuteilsHead from "../images/characters/heads/zuuteils.png";
import leftArrow from "../images/characters/left-arrow.png";
import rightArrow from "../images/characters/right-arrow.png";
import CharacterRace from "../models/CharacterRace";
import { CharacterInfo, CharacterProps, defaultCharacterProps } from "./Character";

const characterGroups: CharacterRace[][] = [
  [
    CharacterRace.Quizon,
    CharacterRace.Peix,
    CharacterRace.Iqroth,
  ],
  [
    CharacterRace.Brelgak,
    CharacterRace.Uhlaeh,
    CharacterRace.Droth,
  ],
  [
    CharacterRace.Zuuteils,
    CharacterRace.Aesliari,
    CharacterRace.Raisut,
  ],
];
const characterInfoDict: {[key: string]: CharacterInfo} = characterInfo;

const allCharacters = characterGroups.flat();

enum TransitionState {
  WillMoveToLeftFromCenter = 'willmovetoleftfromcenter',
  MovingToLeftFromCenter = 'movingtoleftfromcenter',
  AtLeft = 'atleft',
  WillMoveToRightFromLeft = 'willmovetorightfromleft',
  MovingToRightFromLeft = 'movingtorightfromleft',
  WillMoveToCenterFromLeft = 'willmovetocenterfromleft',
  MovingToCenterFromLeft = 'movingtocenterfromleft',
  WillMoveToCenterFromRight = 'willmovetocenterfromright',
  MovingToCenterFromRight = 'movingtocenterfromright',
  WillMoveToLeftFromRight = 'willmovetoleftfromright',
  MovingToLeftFromRight = 'movingtoleftfromright',
  AtCenter = 'atcenter',
  WillMoveToRightFromCenter = 'willmovetorightfromcenter',
  MovingToRightFromCenter = 'movingtorightfromcenter',
  AtRight = 'atright',
};

const styleForState: { [key in TransitionState] : React.CSSProperties } = {
  willmovetoleftfromcenter: {
    zIndex: 0,
    opacity: 1,
    left: '50%',
    pointerEvents: 'none',
  },
  movingtoleftfromcenter: {
    zIndex: 0,
    opacity: 0.01,
    left: '-30%',
    transition: 'all 500ms linear',
    pointerEvents: 'none',
  },
  atleft: {
    zIndex: 0,
    opacity: 0.01,
    left: '-30%',
    pointerEvents: 'none',
  },
  willmovetorightfromleft: {
    zIndex: 0,
    opacity: 0.01,
    left: '-30%',
    pointerEvents: 'none',
  },
  movingtorightfromleft: {
    zIndex: 0,
    opacity: 0.01,
    left: '130%',
    pointerEvents: 'none',
  },
  willmovetocenterfromleft: {
    zIndex: 0,
    opacity: 0.01,
    left: '-30%',
    pointerEvents: 'none',
  },
  movingtocenterfromleft: {
    zIndex: 0,
    opacity: 1,
    left: '50%',
    transition: 'all 500ms linear',
    pointerEvents: 'none',
  },
  willmovetocenterfromright: {
    zIndex: 0,
    opacity: 0.01,
    left: '130%',
    pointerEvents: 'none',
  },
  movingtocenterfromright: {
    zIndex: 0,
    opacity: 1,
    left: '50%',
    transition: 'all 500ms linear',
    pointerEvents: 'none',
  },
  willmovetoleftfromright: {
    zIndex: 0,
    opacity: 0.01,
    left: '130%',
    pointerEvents: 'none',
  },
  movingtoleftfromright: {
    zIndex: 0,
    opacity: 0.01,
    left: '-30%',
    pointerEvents: 'none',
  },
  atcenter: {
    zIndex: 40,
    opacity: 1,
    left: '50%',
  },
  willmovetorightfromcenter: {
    zIndex: 0,
    opacity: 1,
    left: '50%',
    pointerEvents: 'none',
  },
  movingtorightfromcenter: {
    zIndex: 0,
    opacity: 0.01,
    left: '130%',
    transition: 'all 500ms linear',
    pointerEvents: 'none',
  },
  atright: {
    zIndex: 0,
    opacity: 0.01,
    left: '130%',
    pointerEvents: 'none',
  },
};

type ItemTransitionStates = {
  item1State: TransitionState;
  item2State: TransitionState;
  item3State: TransitionState;
};

type CarouselItemProps = {
  itemStyle: React.CSSProperties,
  carouselIndex: number,
  selectedCharacterIndex: number,
  setSelectedCharacterIndex: React.Dispatch<React.SetStateAction<number>>,
};

function CharacterMobile(props: CharacterProps) {
  const [transitionStates, setTransitionStates] = useState<ItemTransitionStates>({
    item1State: TransitionState.AtCenter,
    item2State: TransitionState.AtRight,
    item3State: TransitionState.AtLeft,
  });

  const [selectedCharacterIndex, setSelectedCharacterIndex] = useState<number>(0);

  useEffect(
    () => {
      var movingStates = [
        TransitionState.MovingToLeftFromCenter,
        TransitionState.MovingToRightFromCenter,
        TransitionState.MovingToCenterFromLeft,
        TransitionState.MovingToCenterFromRight,
        TransitionState.MovingToLeftFromRight,
        TransitionState.MovingToRightFromLeft,
      ];

      if (
        movingStates.includes(transitionStates.item1State)
        && movingStates.includes(transitionStates.item2State)
        && movingStates.includes(transitionStates.item3State)
      ) {
        let timerId: NodeJS.Timeout;

        let [newItem1State, newItem2State, newItem3State] = [transitionStates.item1State, transitionStates.item2State, transitionStates.item3State]
          .map(state => {
            switch(state) {
              case TransitionState.MovingToLeftFromCenter:
                return TransitionState.AtLeft;
              case TransitionState.MovingToRightFromCenter:
                return TransitionState.AtRight;
              case TransitionState.MovingToCenterFromLeft:
              case TransitionState.MovingToCenterFromRight:
                return TransitionState.AtCenter;
              case TransitionState.MovingToLeftFromRight:
                return TransitionState.AtLeft;
              case TransitionState.MovingToRightFromLeft:
                return TransitionState.AtRight;
              default:
                return null;
            }
          });
        if (newItem1State && newItem2State && newItem3State) {
          timerId = setTimeout(() => {
            setTransitionStates({
              item1State: newItem1State!,
              item2State: newItem2State!,
              item3State: newItem3State!,
            });
          }, 500);
        }

        return function cleanup() {
          timerId && clearTimeout(timerId);
        };
      }
      var willMoveStates = [
        TransitionState.WillMoveToLeftFromCenter,
        TransitionState.WillMoveToRightFromCenter,
        TransitionState.WillMoveToCenterFromLeft,
        TransitionState.WillMoveToCenterFromRight,
        TransitionState.WillMoveToLeftFromRight,
        TransitionState.WillMoveToRightFromLeft,
      ];
      if (
        willMoveStates.includes(transitionStates.item1State)
        && willMoveStates.includes(transitionStates.item2State)
        && willMoveStates.includes(transitionStates.item3State)
      ) {
        let [newItem1State, newItem2State, newItem3State] = [transitionStates.item1State, transitionStates.item2State, transitionStates.item3State]
          .map(state => {
            switch(state) {
              case TransitionState.WillMoveToLeftFromCenter:
                return TransitionState.MovingToLeftFromCenter;
              case TransitionState.WillMoveToRightFromCenter:
                return TransitionState.MovingToRightFromCenter;
              case TransitionState.WillMoveToCenterFromLeft:
                return TransitionState.MovingToCenterFromLeft;
              case TransitionState.WillMoveToCenterFromRight:
                return TransitionState.MovingToCenterFromRight;
              case TransitionState.WillMoveToLeftFromRight:
                return TransitionState.MovingToLeftFromRight;
              case TransitionState.WillMoveToRightFromLeft:
                return TransitionState.MovingToRightFromLeft;
              default:
                return null;
            }
          });
          if (newItem1State && newItem2State && newItem3State) {
              setTransitionStates({
                item1State: newItem1State!,
                item2State: newItem2State!,
                item3State: newItem3State!,
              });
          }
      }
    },
    [transitionStates],
  );

  function increment() {
    if (transitionStates.item1State === TransitionState.AtCenter) {
      setTransitionStates({
        item1State: TransitionState.WillMoveToLeftFromCenter,
        item2State: TransitionState.WillMoveToCenterFromRight,
        item3State: TransitionState.WillMoveToRightFromLeft,
      });
    } else if (transitionStates.item2State === TransitionState.AtCenter) {
      setTransitionStates({
        item1State: TransitionState.WillMoveToRightFromLeft,
        item2State: TransitionState.WillMoveToLeftFromCenter,
        item3State: TransitionState.WillMoveToCenterFromRight,
      });
    } else if (transitionStates.item3State === TransitionState.AtCenter) {
      setTransitionStates({
        item1State: TransitionState.WillMoveToCenterFromRight,
        item2State: TransitionState.WillMoveToRightFromLeft,
        item3State: TransitionState.WillMoveToLeftFromCenter,
      });
    }
  }

  function decrement() {
    if (transitionStates.item1State === TransitionState.AtCenter) {
      setTransitionStates({
        item1State: TransitionState.WillMoveToRightFromCenter,
        item2State: TransitionState.WillMoveToLeftFromRight,
        item3State: TransitionState.WillMoveToCenterFromLeft,
      });
    } else if (transitionStates.item2State === TransitionState.AtCenter) {
      setTransitionStates({
        item1State: TransitionState.WillMoveToCenterFromLeft,
        item2State: TransitionState.WillMoveToRightFromCenter,
        item3State: TransitionState.WillMoveToLeftFromRight,
      });
    } else if (transitionStates.item3State === TransitionState.AtCenter) {
      setTransitionStates({
        item1State: TransitionState.WillMoveToLeftFromRight,
        item2State: TransitionState.WillMoveToCenterFromLeft,
        item3State: TransitionState.WillMoveToRightFromCenter,
      })
    }
  }

  var character = allCharacters[selectedCharacterIndex];

  return (
    <div className={`flex flex-col items-center justify-center max-w-mobileCharacter ${props.useExtendedDescription ? 'pt-16 pb-5' : ''}`}>
      <div className="relative flex flex-row">
        <img src={leftArrow} className="z-10 h-12 mt-4 mr-mobileArrows" alt="left arrow" onClick={decrement} />
        <img src={rightArrow} className="z-10 h-12 mt-4 ml-mobileArrows" alt="right arrow" onClick={increment} />
        <CarouselItem
          itemStyle={styleForState[transitionStates.item1State]}
          carouselIndex={0}
          selectedCharacterIndex={selectedCharacterIndex}
          setSelectedCharacterIndex={setSelectedCharacterIndex}
        />
        <CarouselItem
          itemStyle={styleForState[transitionStates.item2State]}
          carouselIndex={1}
          selectedCharacterIndex={selectedCharacterIndex}
          setSelectedCharacterIndex={setSelectedCharacterIndex}
        />
        <CarouselItem
          itemStyle={styleForState[transitionStates.item3State]}
          carouselIndex={2}
          selectedCharacterIndex={selectedCharacterIndex}
          setSelectedCharacterIndex={setSelectedCharacterIndex}
        />
      </div>
      <div className="w-full pt-3 text-3xl text-left text-white font-michroma px-9">
        {characterInfoDict[character]?.name || ''}
      </div>
      <div className="w-full py-2 text-3xl font-bold text-left font-marvel px-9 text-gwPurple">
        Rate {characterInfoDict[character]?.rate || ''}
      </div>
      <div className="relative overflow-hidden h-characterPreviewMobile w-characterPreviewMobile">
        <img
          alt={`${character}`}
          className="absolute top-0 left-0 z-10"
          src={require(`../images/characters/mobile-main/${character}.png`).default}
          style={{ pointerEvents: 'none' }}
        />
        <img
          alt=""
          className="absolute top-0 left-0 z-0"
          src={require(`../images/characters/mobile-backgrounds/${character}.png`).default}
          style={{ pointerEvents: 'none' }}
        />
      </div>
      <div className="flex-col">
        <div className="flex px-6 text-xl text-white font-marvel">
          {props.useExtendedDescription ?
          <div className="flex-col -mb-6">
            {
              characterInfoDict[character].loreLines.map((l, i) =>
                <><div key={`roadmapLine${characterInfoDict[character].name}${i}`}>{l}</div><br/></>)
            }
          </div> :
          characterInfoDict[character]?.description || ''}
        </div>
        {!props.useExtendedDescription &&
          <div className="flex flex-row items-center justify-center px-5 pt-5 space-x-4">
            <img alt="Anomaly" src={anomalyIcon} className="w-10 h-10" />
            <img alt="Plasma" src={plasmaIcon} className="w-10 h-10" />
            <img alt="Hydro" src={hydroIcon} className="w-10 h-10" />
            <img alt="Inferno" src={infernoIcon} className="w-10 h-10" />
            <img alt="Terra" src={terraIcon} className="w-10 h-10" />
          </div>
        }
      </div>
    </div>
  );
}

function CarouselItem(props: CarouselItemProps) {
  return (
      <div className="absolute pl-3 left-1/2 -ml-mobileArrows" style={props.itemStyle}>
          <div className="flex flex-row space-x-2">
          {
            characterGroups[props.carouselIndex].map((c, i) => {
              var characterIndex = 3 * props.carouselIndex + i;
              var isSelected = characterIndex === props.selectedCharacterIndex;
              return (
                  <div
                    key={`character-head-${props.carouselIndex}-${i}`}
                    className={`flex w-16 h-16 rounded-full items-center justify-center bg-${isSelected ? 'white' : 'gwPurple'}`}
                    onClick={() => props.setSelectedCharacterIndex(characterIndex)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img className="transform scale-75" alt={`Select ${c}`} src={getHeadImage(c)} />
                  </div>
                );
            })
          }
          </div>
      </div>
  );
}

function getHeadImage(race: CharacterRace) {
  switch(race) {
    case CharacterRace.Aesliari:
      return aesliariHead;
    case CharacterRace.Brelgak:
      return brelgakHead;
    case CharacterRace.Droth:
      return drothHead;
    case CharacterRace.Iqroth:
      return iqrothHead;
    case CharacterRace.Peix:
      return peixHead;
    case CharacterRace.Quizon:
      return quizonHead;
    case CharacterRace.Raisut:
      return raisutHead;
    case CharacterRace.Uhlaeh:
      return uhlaehHead;
    case CharacterRace.Zuuteils:
      return zuuteilsHead;
    default:
      return "";
  }
}

CharacterMobile.defaultProps = defaultCharacterProps;

export default CharacterMobile;