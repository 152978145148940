import Character from "./Character";
import CharacterMobile from "./CharacterMobile";
import starBackgroundImage from '../images/stars-bg.png';
import starMobileBackgroundImage from '../images/stars-bg-mobile.png';
import useDeviceSize from "../util/DeviceSize";


function Lore(){
  const [isMobile, isDesktop] = useDeviceSize();

  return (
    <>
      {isDesktop &&
        <div className="block bg-top" style={{ backgroundImage: `url(${starBackgroundImage})` }}>
          <Character useExtendedDescription={true} />
        </div>
      }
      {isMobile &&
        <div className="flex flex-row justify-center mx-auto bg-top" style={{ backgroundImage: `url(${starMobileBackgroundImage})` }}>
          <CharacterMobile useExtendedDescription={true} />
        </div>
      }
    </>
  )
}

export default Lore;