import Character from '../components/Character';
import CharacterMobile from '../components/CharacterMobile';
import Roadmap from '../components/Roadmap';
import RoadmapMobile from '../components/RoadmapMobile';
import starBackgroundImage from '../images/stars-bg.png';
import starMobileBackgroundImage from '../images/stars-bg-mobile.png';
import useDeviceSize from '../util/DeviceSize';

function Home() {
  const [isMobile, isDesktop] = useDeviceSize();
  let starBackgroundImageFileName = isMobile ? starMobileBackgroundImage : starBackgroundImage;

  return (
    <div className="bg-center bg-no-repeat" style={{ backgroundImage: `url(${starBackgroundImageFileName})` }}>
      <section className="relative items-center">
        {isDesktop &&
          <div className="block">
            <Character />
          </div>
        }
        {isMobile &&
          <div className="flex flex-row justify-center mx-auto">
            <CharacterMobile />
          </div>
        }
      </section>
      <section className="relative">
        {isDesktop &&
					<Roadmap />
        }
        {isMobile &&
					<RoadmapMobile />
        }
      </section>
    </div>
  );
}

export default Home;