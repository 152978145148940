import roadmap from "../content/roadmap.json";
import planet1ImageSmall from "../images/planet-1-small.png";
import planet2Image from "../images/planet-2.png";
import planet3Image from "../images/planet-3.png";
import planet4ImageLarge from "../images/planet-4-large.png";

function Roadmap() {
  return (
    <>
      <div className="absolute top-28 left-9">
        <img src={planet1ImageSmall} alt="" />
      </div>
      <div className="absolute top-60 left-14">
        <img src={planet2Image} alt="" />
      </div>
      <div className="absolute top-96 right-4">
        <img src={planet3Image} alt="" />
      </div>
      <div className="absolute top-planet4TopMobile left-2">
        <img src={planet4ImageLarge} alt="" />
      </div>
      <div className="absolute top-planet1TopMobile right-5">
        <img src={planet1ImageSmall} alt="" />
      </div>
      <div className="absolute top-planet3TopMobile left-4">
        <img src={planet3Image} alt="" />
      </div>
      <div className="p-4 mx-5 text-center mt-11 rounded-3xl bg-gwPurple max-w-roadmapHeader">
        <h2 className="text-3xl font-normal leading-normal text-white uppercase des-shadow font-michroma">
					ROADMAP
				</h2>
      </div>
      <div className="relative h-full mx-auto overflow-hidden wrap xl:w-11/12">
        <div className="absolute h-full border-4 border-gwPurple" style={{ left: '50%' }} />
          <div className="h-10"/>
          {CreateRoadmapItems()}
      </div>
      <div className="h-20"/>
      <div className="pb-4 pr-4 text-right text-white text-extra-s font-michroma">
        *Tentative Release - May Vary
      </div>
    </>
  );
}

function CreateRoadmapItems() {
  return roadmap.items.map((item, index) => {
    // right side
    if (index % 2 === 0) {
      return (
        <div key={`roadmapItem${index}`} className="flex justify-between w-full w-10-12">
          <div className="order-1 w-5/12"/>
          <div className="z-20 flex items-center order-1 mt-6 ml-2 bg-white border-4 rounded-full w-9 h-9 border-gwPurple" />
          <div className="order-1 w-5/12 pr-1 mt-14">
            <div
              className="pl-3 mb-1 text-3xl font-bold leading-tight text-gwPurple time-shadow font-marvel"
              style={{ WebkitTextStroke: ".35px white" }}>
              {item.title}
            </div>
            <div className="flex flex-col justify-start mr-0 text-xs text-white des-shadow font-michroma">
              {item.contentLines.map((l, i) => <div key={`roadmapLine${index}${i}`}>{l}</div>)}
            </div>
          </div>
        </div>
      )
    // left side
    } else {
      return (
          <div key={`roadmapItem${index}`} className="flex flex-row-reverse justify-between w-full">
            <div className="order-1 w-5/12" />
            <div className="z-20 flex items-center order-1 mt-6 ml-2 bg-white border-4 rounded-full w-9 h-9 border-gwPurple" />
            <div className="order-1 w-5/12 mt-14">
              <div
                className="mb-1 text-3xl font-bold leading-tight text-right text-gwPurple time-shadow font-marvel"
                style={{ WebkitTextStroke: ".35px white" }}>
                {item.title}
              </div>
              <div className="flex flex-col justify-end mr-0 text-xs text-right text-white des-shadow font-michroma">
                {item.contentLines.map((l, i) => <div key={`roadmapLine${index}${i}`}>{l}</div>)}
              </div>
            </div>
          </div>
      )
    }
  });
}

export default Roadmap;