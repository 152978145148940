enum CharacterRace {
  Aesliari = 'aesliari',
  Brelgak = 'brelgak',
  Droth = 'droth',
  Iqroth = 'iqroth',
  Peix = 'peix',
  Quizon = 'quizon',
  Raisut = 'raisut',
  Uhlaeh = 'uhlaeh',
  Zuuteils = 'zuuteils',
}

export default CharacterRace;