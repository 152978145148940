import React, { useState } from "react";

import aesliariHead from "../images/characters/heads/aesliari.png";
import brelgakHead from "../images/characters/heads/brelgak.png";
import drothHead from "../images/characters/heads/droth.png";
import iqrothHead from "../images/characters/heads/iqroth.png";
import peixHead from "../images/characters/heads/peix.png";
import quizonHead from "../images/characters/heads/quizon.png";
import raisutHead from "../images/characters/heads/raisut.png";
import uhlaehHead from "../images/characters/heads/uhlaeh.png";
import zuuteilsHead from "../images/characters/heads/zuuteils.png";
import anomalyIcon from "../images/anomaly_icon.png";
import hydroIcon from "../images/hydro_icon.png";
import infernoIcon from "../images/inferno_icon.png";
import plasmaIcon from "../images/plasma_icon.png";
import terraIcon from "../images/terra_icon.png";
import characterInfo from '../content/characterInfo.json';
import CharacterRace from "../models/CharacterRace";

export type CharacterInfo = {
  description: string;
  loreLines: string[];
  name: string;
  rate: string;
};

export type CharacterProps = {
  useExtendedDescription: boolean;
}

type CharacterComponentState = {
  selectedCharacter: CharacterRace;
};

const characterInfoDict: {[key: string]: CharacterInfo} = characterInfo;

export const defaultCharacterProps: CharacterProps = {
  useExtendedDescription: false,
};

function Character(props: CharacterProps) {
  const [state, setState] = useState<CharacterComponentState>({
    selectedCharacter: CharacterRace.Quizon,
  });

  return (
    <div className="justify-center">
      <div className={`flex items-center justify-center ${props.useExtendedDescription ? 'pt-32' : 'pt-16 mb-4'} pb-10 text-4xl font-michroma`}>
        {props.useExtendedDescription ? <>LORE</> : <>CHARACTERS</>}
      </div>
      <div className="flex items-center justify-center space-x-16 mx-11 min-w-max">
        {createCharacterHeads(state, setState)}
      </div>
      <div className={`flex flex-row items-center justify-center ${props.useExtendedDescription ? '' : 'ml-24'}`}>
        {createCharacterPreview(state, props)}
      </div>
    </div>
  );
}

function createCharacterHeads(state: CharacterComponentState, setState: React.Dispatch<React.SetStateAction<CharacterComponentState>>) {
  var charactersOrder: CharacterRace[] = [
    CharacterRace.Quizon,
    CharacterRace.Peix,
    CharacterRace.Iqroth,
    CharacterRace.Brelgak,
    CharacterRace.Uhlaeh,
    CharacterRace.Droth,
    CharacterRace.Zuuteils,
    CharacterRace.Aesliari,
    CharacterRace.Raisut,
  ];
  return charactersOrder.map(c => (
    <div
      className={`flex w-20 h-20 rounded-full items-center justify-center bg-${getHeadBackgroundClass(c, state)}`}
      key={`${c}-head-button`}
      onClick={
        () => setState({
          selectedCharacter: c,
        })
      }
      style={{ cursor: 'pointer' }}
    >
      <img alt={`Select ${c}`} src={getHeadImage(c)} />
    </div>
  ));
}

function createCharacterPreview(state: CharacterComponentState, props: CharacterProps) {
  var character = state.selectedCharacter;
  var variantImage = <></>;
  if (hasVariant(character)) {
    variantImage = <img
      alt={`${character} (variant)`}
      className="absolute top-0 z-20 left-characterVariantLeft"
      src={require(`../images/characters/variants/${character}-01.png`).default}
      style={{ pointerEvents: 'none' }}
    />
  }
  return (
    <div className={`flex ${props.useExtendedDescription ? 'flex-col' : ''}`}>
      <div className={getCharacterDescriptionClass(props.useExtendedDescription)}>
        <div className="h-full justify-items-start">
          <div className={`${props.useExtendedDescription ? 'pt-4' : 'pt-20'} text-6xl text-white font-marvel`}>
            {characterInfoDict[character]?.name || ''}
          </div>
          <div className="py-2 text-4xl font-bold font-marvel text-gwPurple">
            Rate {characterInfoDict[character]?.rate || ''}
          </div>
          <div className={`text-3xl text-white font-marvel ${props.useExtendedDescription ? 'w-full' : 'w-characterDescription'}`}>
            {getCharacterDescription(character, props)}
          </div>
        </div>
        { !props.useExtendedDescription &&
          <div className="flex flex-row items-center justify-center mt-4 space-x-8 mr-14 min-w-max">
            <img alt="Anomaly" src={anomalyIcon} />
            <img alt="Plasma" src={plasmaIcon} />
            <img alt="Hydro" src={hydroIcon} />
            <img alt="Inferno" src={infernoIcon} />
            <img alt="Terra" src={terraIcon} />
          </div>
        }
      </div>

      <div className={getCharacterPreviewClass(props.useExtendedDescription)}>
        <div className={`${props.useExtendedDescription ? 'mr-16 min-w-characterBg' : ''}`}>
          {variantImage}
          <img
            alt={character}
            className="absolute z-10 top-characterMainTop left-characterMainLeft h-characterMain min-w-characterMain w-characterMain"
            src={require(`../images/characters/main/${character}.png`).default}
            style={{ pointerEvents: 'none' }}
          />
          <img
            alt=""
            className="absolute z-0 -top-12 -left-18 w-characterBg min-w-characterBg h-characterBg"
            src={require(`../images/characters/backgrounds/${character}.png`).default}
            style={{ pointerEvents: 'none' }}
          />
        </div>
      </div>
    </div>
  );
}

function getCharacterDescription(character: CharacterRace, props: CharacterProps) {
  var characterInfo = characterInfoDict[character];
  if (!characterInfo) {
    return <></>;
  }
  if (props.useExtendedDescription) {
    return characterInfo.loreLines.map((l, i) => <><div key={`roadmapLine${characterInfo.name}${i}`}>{l}</div><br/></>)
  }
  return <>{characterInfo.description}</>;
}

function getHeadBackgroundClass(race: CharacterRace, state: CharacterComponentState): string {
    return state.selectedCharacter === race ? 'white' : 'gwPurple';
}

function getCharacterDescriptionClass(useExtendedDescription: boolean) {
  return useExtendedDescription ?
    "flex flex-row w-2/5 mx-auto order-2" :
    "flex flex-col pl-14";
}

function getCharacterPreviewClass(useExtendedDescription: boolean) {
  return useExtendedDescription ?
    "relative mx-auto min-w-characterBg overflow-hidden h-characterPreview order-1 -pl-16" :
    "relative overflow-hidden h-characterPreview w-characterPreview";
}

function hasVariant(race: CharacterRace): boolean {
  switch(race) {
    case CharacterRace.Brelgak:
    case CharacterRace.Uhlaeh:
    case CharacterRace.Raisut:
    case CharacterRace.Aesliari:
    case CharacterRace.Zuuteils:
    case CharacterRace.Droth:
      return true;
  }

  return false;
}

function getHeadImage(race: CharacterRace): string {
  switch(race) {
    case CharacterRace.Aesliari:
      return aesliariHead;
    case CharacterRace.Brelgak:
      return brelgakHead;
    case CharacterRace.Droth:
      return drothHead;
    case CharacterRace.Iqroth:
      return iqrothHead;
    case CharacterRace.Peix:
      return peixHead;
    case CharacterRace.Quizon:
      return quizonHead;
    case CharacterRace.Raisut:
      return raisutHead;
    case CharacterRace.Uhlaeh:
      return uhlaehHead;
    case CharacterRace.Zuuteils:
      return zuuteilsHead;
    default:
      return "";
  }
}

Character.defaultProps = defaultCharacterProps;

export default Character;