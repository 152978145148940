import { Link } from 'react-router-dom';
import discordIcon from '../images/discord-icon.png';
import footerLogo from '../images/footer-logo.png';
import pancakeSwap from '../images/pancakeswap-cake-logo.png';
import seamlessSwap from '../images/seamlessswap.png';
import telegramIcon from '../images/telegram-icon.png';
import twitterIcon from '../images/twitter-icon.png';

function Footer() {
  return (
    <div className="py-16 bg-black min-w-footer md:min-w-mobile">
      <div className="container px-6">
        <div className="grid grid-cols-3 gap-4">
          <div className="mb-10 lg:mb-0">
            <Link to="/" className="block mb-4">
              <img src={footerLogo} alt=""/>
            </Link>
            <p className="font-normal leading-normal text-white time-shadow font-michroma text-5">
              All rights reserved. <br/>
              {(new Date().getFullYear())} &copy; GALAXYWARRIORS
            </p>
          </div>
          <div className="px-10 font-michroma">
            <ul>
              <li className="mb-5">
                <Link to="/" className="time-shadow text-white text-4.5 font-normal leading-normal">
                  HOME
                </Link>
              </li>
              <li className="mb-5">
                <Link to="/coming-soon" className="time-shadow text-white text-4.5 font-normal leading-normal">
                  ABOUT
                </Link>
              </li>
              <li className="mb-5">
								<Link to="/files/GWT-Litepaper.pdf" target="_blank" className="time-shadow text-white text-4.5 font-normal leading-normal">
									LITE PAPER
									</Link>
              </li>
              <li className="mb-5">
                <Link to="/blog" className="time-shadow text-white text-4.5 font-normal leading-normal">
                  BLOG
                </Link>
              </li>
              <li className="mb-5">
                <Link to="/coming-soon" className="time-shadow text-white text-4.5 font-normal leading-normal">
                  FAQ
                </Link>
              </li>
              <li>
                <a href="https://forms.gle/L1LwkTJkUHvfihmXA" target="_blank" rel="noreferrer" className="time-shadow text-white text-4.5 font-normal leading-normal">
                  CONTACT US
                </a>
              </li>
            </ul>
          </div>
          <div className="max-w-footerRightCol">
            <h4 className="time-shadow font-michroma text-white text-4.5 font-normal leading-normal mb-2 uppercase">SOCIAL</h4>
            <ul className="flex items-center gap-4 mb-4 list-none">
              <li>
                <a href="https://twitter.com/_galaxywarriors">
                  <img src={twitterIcon} alt="Twitter"/>
                </a>
              </li>
              <li>
                <a href="https://discord.gg/2H6PC25Xc3">
                  <img src={discordIcon} alt="Discord"/>
                </a>
              </li>
              <li>
                <a href="https://t.me/GalaxyWarriorsGame">
                  <img src={telegramIcon} alt="Telegram"/>
                </a>
              </li>
            </ul>
            <div>
              <h4 className="time-shadow font-michroma text-white text-4.5 font-normal leading-normal mb-2 uppercase">
								SHEESH
              </h4>
              <button onClick={() => window.open('https://dex.seamlessswap.finance/#/swap?outputCurrency=0x564F184692952ADF6A9512a13dc49cAF45E76b12', '_blank')}
                className="relative flex items-center justify-start w-full px-6 pl-4 pr-3 mb-3 font-normal text-left text-white border-2 border-solid rounded-full bg-black-500 border-seamlessSwapYellow font-michroma text-buyTokenButton py-buyTokenButton time-shadow focus:outline-none">
                <img className="inline-block mr-3 bg-black rounded-full" src={seamlessSwap} alt=""/>
                Buy on SeamlessSwap
              </button>
              <button onClick={() => window.open('https://pancakeswap.finance/swap?outputCurrency=0x564F184692952ADF6A9512a13dc49cAF45E76b12', '_blank')}
                className="relative flex items-center justify-start w-full px-6 pl-4 pr-3 mb-3 font-normal text-left text-white break-words border border-solid rounded-full bg-pancakeSwapBlue font-michroma text-buyTokenButton py-buyTokenButton time-shadow focus:outline-none">
                <img className="inline-block mr-3" src={pancakeSwap} alt=""/>
                Buy on Pancakeswap
              </button>
              <h4 className="time-shadow font-michroma text-white text-4.5 font-normal leading-normal mb-2 break-words">
                GalaxyWarriorsToken
              </h4>
              <button onClick={() => window.open('https://dex.seamlessswap.finance/#/swap?outputCurrency=0xFA5E7F013588b1c76E7218f2799e125bfD3D8D84', '_blank')}
                className="relative flex items-center justify-start w-full px-6 pl-4 pr-3 mb-3 font-normal text-left text-white break-words border-2 border-solid rounded-full bg-black-500 border-seamlessSwapYellow font-michroma text-buyTokenButton py-buyTokenButton time-shadow focus:outline-none">
                <img className="inline-block mr-3 bg-black rounded-full" src={seamlessSwap} alt=""/>
                Buy on SeamlessSwap
              </button>
              <button onClick={() => window.open('https://pancakeswap.finance/swap?outputCurrency=0xFA5E7F013588b1c76E7218f2799e125bfD3D8D84', '_blank')}
                className="relative flex items-center w-full px-6 pl-4 pr-3 mb-3 font-normal text-left text-white break-words border border-solid rounded-full bg-pancakeSwapBlue font-michroma text-buyTokenButton py-buyTokenButton time-shadow focus:outline-none">
                <img className="inline-block mr-3" src={pancakeSwap} alt=""/>
                Buy on Pancakeswap
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;