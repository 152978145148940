import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ColiseumHeaderBg from '../images/coliseum-header-bg.png';
import ColiseumHeaderMobileBg from '../images/coliseum-header-mobile-bg.png';
import ArrowLeft from '../images/characters/left-arrow.png';
import ArrowRight from '../images/characters/right-arrow.png';
import useDeviceSize from '../util/DeviceSize';
import blogMetaData from '../content/blogMetaData.json';

type BlogStateListItem = {
	blogIndex: string;
	headerText: string;
	imageUrl: string;
	paragraphText: string;
};

type BlogState = {
	listItems: BlogStateListItem[][];
	pageIndex: number;
};

function Blog() {
	const [isMobile] = useDeviceSize();

	if (isMobile) {
		window.scrollTo(0, 0);
	}

	const [state, setState] = useState<BlogState>(
		{
			listItems: [[]],
			pageIndex: 0,
		},
	);

	useEffect(() => {
		let paginatedMetaData = paginateBlogMetaData();
		setState({
			...state,
			pageIndex: 0,
			listItems: paginatedMetaData,
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{isMobile &&
				<div className="relative text-center">
					<img className="w-full" alt="" src={ColiseumHeaderMobileBg} />
					<div className="absolute top-0 w-full h-full">
						<div className='flex items-center justify-center h-full text-4xl font-michroma time-shadow'>
							BLOG
						</div>
					</div>
				</div>
			}
			{!isMobile &&
				<div className="relative text-center">
					<img className="w-full" alt="" src={ColiseumHeaderBg} />
					<div className="absolute top-0 w-full h-full">
						<div className='flex items-center justify-center h-full text-4xl font-michroma time-shadow'>
							BLOG
						</div>
					</div>
				</div>
			}
			<div className={`container ${isMobile ? 'mt-9 px-4' : 'mt-blogHeader'}`}>
				{ state.pageIndex === 0 &&
					<div className='relative mb-6'>
						<div className='absolute left-10 bottom-10'>
							<div className='mb-6 font-normal leading-normal text-white time-shadow text-md'>
								{getFieldFromCurrentListItem(state, 'headerText')}
							</div>
							<Link className='inline-block px-4 py-2 duration-300 border-2 border-solid rounded-full bg-blogGray text-smm text-blogBlue hover:text-blogGray hover:bg-transparent border-blogGray font-roboto' to={`/blog/${getFieldFromCurrentListItem(state, 'blogIndex')}`}>Read More</Link>
						</div>
						<img alt="" className='object-cover w-full rounded-xl shadow-time h-blogTopItemImageHeight' src={`${process.env.PUBLIC_URL}/blog/${getFieldFromCurrentListItem(state, 'imageUrl')}`} />
					</div>
				}
				<div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-3'} gap-8 ${state.pageIndex ? (isMobile ? 'mt-12' : 'mt-48') : ''}`}>
					{state.listItems[state.pageIndex]?.filter((_, index) => index || state.pageIndex)?.map((listitem, index) => (
						<div key={index} className="w-full shadow-time rounded-3xl">
							<div className='rounded-tl-3xl rounded-tr-3xl'>
								<img alt="" className='object-cover w-full h-blogItemImageHeight rounded-tl-3xl rounded-tr-3xl' src={`${process.env.PUBLIC_URL}/blog/${listitem.imageUrl}`} />
							</div>
							<div className='px-6 pt-6 pb-8 bg-white rounded-bl-3xl rounded-br-3xl'>
								<h3 className={`${isMobile ? 'mb-4' : 'mb-6'} text-xl font-normal leading-normal time-shadow text-gwPurple`}>{listitem.headerText}</h3>
								<p className={` ${isMobile ? 'h-24' : 'h-28'} font-normal text-black text-xmd leadding-normal font-dmsans`}>{listitem.paragraphText}</p>
								<Link className='inline-block px-6 py-2 text-lg text-white duration-300 border border-solid rounded-full bg-blogBlue hover:text-blogBlue hover:bg-transparent border-blogBlue font-roboto' to={`/blog/${getFieldFromCurrentListItem(state, 'blogIndex', index + (state.pageIndex === 0 ? 1 : 0))}`}>Read More</Link>
							</div>
						</div>
					))}
				</div>
				{PageControls(state, setState)}
			</div>
		</>
	);
}

function PageControls(state: BlogState, setState: React.Dispatch<BlogState>) {
	const incrementButtonEnabled = () => state.pageIndex < state.listItems.length - 1;
	const incrementPage = () => {
		if (incrementButtonEnabled()) {
			setState({
				...state,
				pageIndex: state.pageIndex + 1,
			});
		}
	};
	const decrementButtonEnabled = () => state.pageIndex > 0;
	const decrementPage = () => {
		if (decrementButtonEnabled()) {
			setState({
				...state,
				pageIndex: state.pageIndex - 1,
			});
		}
	};
	const setPage = (index: number) => () => setState({
		...state,
		pageIndex: index,
	});
	let pageCount = Math.min(state.listItems.length, 9);
	return (
		<div className='flex items-center justify-center gap-4 py-16 text-center'>
			<div className='cursor-pointer' onClick={decrementPage}><img alt="" src={ArrowLeft} /></div>
			{
				Array(pageCount).fill(0).map((_, index) => {
					let offset = state.pageIndex > 8 ? state.pageIndex - 8 : 0;
					let shiftedIndex = index + offset;
					return (
						<div
							className={
								`inline-block py-5 text-xs text-center duration-300 border border-solid rounded-full cursor-pointer font-michroma h-14 w-14 page-blogBlue border-blogBlue ${
									shiftedIndex === state.pageIndex ?
									'text-blogBlue hover:bg-blogBlue hover:text-white bg-white' :
									'bg-blogBlue hover:bg-transparent hover:text-blogBlue text-white'
								}`
							}
							onClick={setPage(shiftedIndex)}
							key={`page-control-${shiftedIndex}`}
						>{shiftedIndex + 1}</div>
					);
				}
				)
			}
			<div className='cursor-pointer' onClick={incrementPage}><img alt="" src={ArrowRight} /></div>
		</div>
	)
}

function getFieldFromCurrentListItem(state: BlogState, field: keyof BlogStateListItem, innerIndex?: number): string {
	var currentItem = state.listItems[state.pageIndex];
	if (currentItem && currentItem[innerIndex ?? 0]) {
		return (currentItem[innerIndex ?? 0][field]) as string || '';
	}
	return '';
}

function paginateBlogMetaData(): BlogStateListItem[][] {
	const pageSize = 9;
	let pageCount = blogMetaData.length <= pageSize + 1 ? 1 : Math.ceil(1 + (blogMetaData.length - pageSize - 1) / pageSize);
	blogMetaData.sort((a, b) => parseFloat(b.blogIndex) - parseFloat(a.blogIndex));

	return Array(pageCount).fill(0)
		.map((_, index): BlogStateListItem[] =>
			blogMetaData.slice(
				index * pageSize + (index ? 1 : 0),
				Math.min((index + 1) * pageSize + 1, blogMetaData.length)
			)
		);
}

export default Blog;