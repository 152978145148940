import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import useDeviceSize from '../util/DeviceSize';

type BlogEntryState = {
  id: number;
  content: string;
  valid: boolean;
};

function BlogEntry() {
  const [isMobile] = useDeviceSize();
  let id = parseFloat(useParams()['id'] ?? '-1');
  if (Number.isNaN(id)) {
    id = -1;
  }

  const [state, setState] = useState<BlogEntryState>(
    {
      id,
      content: '',
      valid: true,
    }
  );

	useEffect(() => {
		async function fetchBlogPost() {
      if (id === -1) {
        setState({
          ...state,
          content: '',
          valid: false,
        });
        return;
      }

			const response = await fetch(`${process.env.PUBLIC_URL}/blog/${id}.md`, {
				headers: {
					'Accept': 'text/html',
					'Content-Type': 'text/html',
				}
			});

      let content = "";
      if (response.ok) {
        content = parseEntryText(await response.text());
      }

      if (!response.ok || content.startsWith("<!DOCTYPE html>")) {
        setState({
          ...state,
          content: '404 Not Found',
          valid: false,
        });
        return;
      }

			setState({
        ...state,
				content,
        valid: true,
			});
		}
		fetchBlogPost();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  return (
    <>
      <div className='h-24 md:h-8' />
      <div className='container p-6' style={isMobile ? {} : {minHeight: "calc(100vh - 640px)"}}>
        <ReactMarkdown children={state.content} rehypePlugins={[rehypeRaw]} />
      </div>
    </>
  );
}

function parseEntryText(content: string): string {
  return content
    .replaceAll(/\n\s*\n\s*/g, "\n\n<br/>\n\n")
    .replaceAll(/!\[.*\]\s*\(([^\s]*)\)/g, "\n<p align=\"center\"><img src=\"$1\" /></p>\n")
    .replaceAll(/\[([^\]]*)\]\((.*)\)/g, "\n<a href=\"$2\" style=\"text-decoration: underline\">$1</a>");
}

export default BlogEntry;