import roadmap from "../content/roadmap.json";
import planet1ImageLarge from "../images/planet-1-large.png";
import planet1ImageSmall from "../images/planet-1-small.png";
import planet2Image from "../images/planet-2.png";
import planet3Image from "../images/planet-3.png";
import planet4ImageLarge from "../images/planet-4-large.png";
import planet4ImageSmall from "../images/planet-4-small.png";

function Roadmap() {
  return (
    <>
      <div className="absolute top-32 left-20">
        <img src={planet1ImageLarge} alt="" />
      </div>
      <div className="absolute top-60 right-32">
        <img src={planet1ImageSmall} alt="" />
      </div>
      <div className="absolute top-planet2Top left-24">
        <img src={planet2Image} alt="" />
      </div>
      <div className="absolute bottom-10 left-1/3">
        <img src={planet3Image} alt="" />
      </div>
      <div className="absolute top-planet3Top right-20">
        <img src={planet3Image} alt="" />
      </div>
      <div className="absolute bottom-44 left-24">
        <img src={planet4ImageLarge} alt="" />
      </div>
      <div className="absolute bottom-56 right-80">
        <img src={planet4ImageSmall} alt="" />
      </div>
      <div className="px-10 py-10 mx-auto mt-5 text-center rounded-lg bg-gwPurple max-w-roadmapHeader">
        <h2 className="mb-4 text-3xl font-normal leading-normal text-white uppercase des-shadow font-michroma">ROADMAP</h2>
        <p className="relative w-6/12 mx-auto text-base font-normal leading-normal text-white des-shadow text-md font-michroma">
          The Sheesh Team began Galaxy Warriors in Q3 2021, and the future for Galaxy Warriors is very
          bright
        </p>
      </div>
      <div className="relative h-full mx-auto overflow-hidden wrap xl:w-11/12">
        <div className="absolute h-full border-4 border-gwPurple" style={{ left: '50%' }}>
        </div>
        {CreateRoadmapItems()}
      </div>
      <div className="h-20"/>
      <div className="pb-4 pr-8 text-lg text-right text-white font-michroma">
        *Tentative Release - May Vary
      </div>
    </>
  );
}

function CreateRoadmapItems() {
  return roadmap.items.map((item, index) => {
    // right side
    if (index % 2 === 0) {
      return (
        <div className="flex items-center justify-between w-full pt-8 mb-8 right-timeline w-10-12">
          <div className="order-1 w-6/12"></div>
          <div className="z-20 flex items-center order-1 w-8 h-8 ml-2 bg-white border-4 rounded-full border-gwPurple">
          </div>
          <div className="order-1 w-6/12 pl-8 pr-4">
            <div
              className="mb-1 text-6xl font-bold leading-tight text-gwPurple time-shadow font-marvel"
              style={{ WebkitTextStroke: ".25px black" }}>
              {item.title}
            </div>
            <div className={`flex flex-col text-2xl leading-9 text-white des-shadow justify-start font-michroma`}>
              {item.contentLines.map((l, i) => <div key={`roadmapLine${index}${i}`}>{l}</div>)}
            </div>
          </div>
        </div>
      );
    // left side
    } else {
      return (
        <div className="flex flex-row-reverse items-center justify-between w-full mb-16 left-timeline">
          <div className="order-1 w-6/12"></div>
          <div className="z-20 flex items-center order-1 w-8 h-8 ml-2 bg-white border-4 rounded-full border-gwPurple">
          </div>
          <div className="order-1 w-6/12 pl-12 pr-8 ml-auto">
            <div
              className="mb-1 text-6xl font-bold leading-tight text-right text-gwPurple time-shadow font-marvel"
              style={{ WebkitTextStroke: ".25px black" }}>
              {item.title}
            </div>
            <div className="flex flex-col justify-end mr-0 text-2xl leading-9 text-right text-white des-shadow font-michroma">
              {item.contentLines.map((l, i) => <div key={`roadmapLine${index}${i}`}>{l}</div>)}
            </div>
          </div>
        </div>
      );
    }
  });
}

export default Roadmap;