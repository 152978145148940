import { useMediaQuery } from "react-responsive";

function useDeviceSize() {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)",
  });
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  return [isMobile, isDesktop];
}

export default useDeviceSize;